




















































































































































































































































































































.f_1 {
  width: 350px;
}
.f_2 {
  width: 500px;
}
